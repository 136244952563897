import * as React from "react"
import {
  CreateButton,
  EditButton,
  Pagination,
  List,
  DeleteWithConfirmButton,
} from "react-admin"
import { Typography, Box } from "@mui/material"
import { Datagrid, TextField, ImageField } from "react-admin"

const Empty = () => (
  <Box
    textAlign="center"
    className="empty-block"
    style={{ width: "100%" }}
    m={1}
  >
    <Typography variant="h4" paragraph>
      No Book Type available
    </Typography>
    <Typography variant="body1">Click to Create Item </Typography>
    <CreateButton />
  </Box>
)

const BookTypeList = () => {
  return (
    <>
      <div style={{ height: "30px" }}> </div>
      <List
        sort={{ field: "order", order: "ASC" }}
        perPage={10}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
        component="div"
        empty={<Empty />}
      >
        <Datagrid
          optimized
          sx={{
            "& .column-groups": {
              md: { display: "table-cell" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <ImageField
            sx={{
              "& img": {
                maxWidth: 100,
                maxHeight: 100,
                objectFit: "contain",
              },
            }}
            source="cover_book_image"
            label="Book Image"
            sortable={false}
          />
          <TextField
            source="cover_book_title"
            label="Book Title"
            sortable={false}
          />

          <TextField
            source="cover_book_type"
            label="Book Type"
            sortable={false}
          />
          <EditButton />
          <DeleteWithConfirmButton />
        </Datagrid>
      </List>
    </>
  )
}

export default BookTypeList
