import * as React from "react"
import { useTranslate, useRecordContext } from "react-admin"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

import { Order } from "../types"
import { TableCellRight } from "./TableCellRight"

const Basket = () => {
  const record = useRecordContext<Order>()
  const translate = useTranslate()

  if (!record || !record?.bookData) return null

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.reference")}
          </TableCell>
          <TableCell>
            {translate("resources.commands.fields.basket.book_type")}
          </TableCell>

          <TableCell>
            {translate("resources.commands.fields.basket.book_size")}
          </TableCell>
          <TableCell>
            {translate("resources.commands.fields.basket.pdf")}
          </TableCell>

          <TableCellRight>
            {translate("resources.commands.fields.basket.unit_price")}
          </TableCellRight>
          <TableCellRight>
            {translate("resources.commands.fields.basket.quantity")}
          </TableCellRight>
          <TableCellRight>
            {translate("resources.commands.fields.basket.total")}
          </TableCellRight>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={"orderItem"}>
          <TableCell>
            <div>
              <img src={record.bookData.book_cover_url} width="200px" />
            </div>
          </TableCell>
          <TableCell>
            <div>{record.book_type}</div>
          </TableCell>

          <TableCell>
            <div>{record.book_size}</div>
          </TableCell>
          <TableCell>
            <div>
              {record.bookData?.book_pdf_url ? (
                <a href={record.bookData?.book_pdf_url} target="_blank">
                  <img
                    src={
                      record.bookData.pdf_thumnail ||
                      record.bookData.book_cover_url
                    }
                    height="80px"
                  />
                </a>
              ) : (
                " - "
              )}
            </div>
          </TableCell>
          <TableCellRight>
            {parseFloat(record.bookData.price).toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
          <TableCellRight>{record.book_qty}</TableCellRight>
          <TableCellRight>
            {(record.bookData.price * record.book_qty).toLocaleString(
              undefined,
              {
                style: "currency",
                currency: "USD",
              }
            )}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default Basket
