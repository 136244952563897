import * as React from "react"
import { Link, FieldProps, useRecordContext } from "react-admin"
import { SxProps, Avatar, Typography } from "@mui/material"

import PdfIcon from "@mui/icons-material/PictureAsPdf"

import { Order } from "../types"

const OrderLinkField = (props: FieldProps<Order>) => {
  const record = useRecordContext<Order>()
  if (!record.bookData || !record.bookData?.book_pdf_url) {
    return (
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      >
        ---
      </Typography>
    )
  }
  const size = 100
  return (
    <a href={record.bookData?.book_pdf_url} target="_blank">
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      >
        <PdfIcon />
      </Typography>
    </a>
  )
}

OrderLinkField.defaultProps = {
  source: "order_number",
}

export default OrderLinkField
