import * as React from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useUpdate,
  useRecordContext,
  useNotify,
  useRedirect,
  RadioButtonGroupInput,
  DateTimeInput,
  NumberInput,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"
import { validateForm } from "./PromoCodeCreate"

const PromoCodeEdit = () => {
  const redirect = useRedirect()

  const [update] = useUpdate()
  const record = useRecordContext()
  const notify = useNotify()
  const onUpdate = async (data: any) => {
    update(
      `promo-codes`,
      {
        id: data.id,
        data,
        previousData: record,
      },
      {
        onSuccess: (data) => {
          // success side effects go here
          redirect("/promo-codes")
          notify("promo code Updated")
        },
        onError: (error: any) => {
          // failure side effects go here
          notify(`promo code update error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <Edit title={"Edit Promo Code"}>
      <Separator />

      <SimpleForm validate={validateForm} onSubmit={onUpdate}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {"Edit Promo Code"}
              </Typography>
              <TextInput type="text" source="name" isRequired fullWidth />
              <RadioButtonGroupInput
                source="type"
                choices={[
                  { id: 1, name: "Percentage" },
                  { id: 2, name: "Flat" },
                ]}
              />
              <Box
                display={{ xs: "block", sm: "flex" }}
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <NumberInput
                  sx={{ width: "50%", display: "flex" }}
                  type="text"
                  source="amount"
                  label="Enter Amount / Percentage"
                  isRequired
                  fullWidth
                />
              </Box>
              <TextInput type="text" source="promo_code" isRequired fullWidth />
              <Separator />
              <Box
                display={{ xs: "block", sm: "flex" }}
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <DateTimeInput
                  source="starts_at"
                  sx={{ width: "48%", display: "flex" }}
                />

                <DateTimeInput
                  source="expires_at"
                  sx={{ width: "48%", display: "flex" }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const Separator = () => <Box pt="1em" />

export default PromoCodeEdit
