import * as React from "react"
import { useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  FileInput,
  ImageField,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  NumberInput,
  FormDataConsumer,
} from "react-admin"
import { Box, Typography } from "@mui/material"
import axios from "axios"
import config from "../config/config"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.display_image) {
    errors.display_image = "ra.validation.required"
  }

  return errors
}

const BookManageCreate = () => {
  const [file, setFile] = useState(undefined)
  const [bookImages, setBookImages] = useState(undefined)
  const [bookBenifits, setBookBenifits] = useState([{ img: "", benifit: "" }])

  const uploadImage = (event: any, type: any) => {
    // const apiUrl = config.API_URL
    // if (event && event.target && event.target.files && event.target.files[0]) {
    //   let file = event.target.files[0]
    //   let formData = new FormData()
    //   formData.append("file", file)
    //   axios
    //     .post(`${apiUrl}/upload`, formData)
    //     .then(function (response) {
    //       if (type === "benifit") {
    //         const tempBookBenifits = [...bookBenifits]
    //         tempBookBenifits[0].img = response.data.data
    //         setBookBenifits(tempBookBenifits)
    //       }
    //       if (type === "mainImage") {
    //         setFile(response.data.data)
    //       }
    //     })
    //     .catch(function (err) {
    //       console.log(err)
    //       setFile(undefined)
    //     })
    // }
  }

  const uploadBookImages = (event: any) => {
    const apiUrl = config.API_URL
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const files = event.target.files
      let formData = new FormData()
      let count = 0
      for (const key in files) {
        if (count < files.length) {
          formData.append("files", files[key])
        }
        count++
      }
      axios
        .post(`${apiUrl}/upload-multiple`, formData)
        .then(function (response) {
          setBookImages(response.data.data)
        })
        .catch(function (err) {
          console.log(err)
          setBookImages(undefined)
        })
    }
  }

  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const itemSave = async (data: any) => {
    data.display_image = file
    data.book_images = bookImages
    data.book_benifits = bookBenifits
    create(
      `book-manager`,
      {
        data: data,
      },
      {
        onSuccess: (data) => {
          redirect("/book-manager")
          notify("Book Type Updated")
        },
        onError: (error: any) => {
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Create redirect="show">
        <SimpleForm
          sx={{ maxWidth: 600 }}
          defaultValues={{
            book_type: "1",
            book_benifits: [
              {
                img: "",
                benifit: "",
              },
            ],
          }}
          validate={validateForm}
          onSubmit={itemSave}
        >
          <Box display={{ xs: "block", sm: "flex" }}>
            <SelectInput
              source="book_type"
              choices={[
                {
                  id: 1,
                  name: "Hard Cover",
                },
                {
                  id: 2,
                  name: "Soft Cover",
                },
              ]}
            />
          </Box>
          <SectionTitle label="resources.bookTypes.coverImage" />
          <FileInput
            source="display_image"
            label=""
            accept="image/*"
            onChange={(e) => uploadImage(e, "mainImage")}
          >
            <ImageField source="src" title="title" />
          </FileInput>
          <Separator />
          <Separator />

          <SectionTitle label="resources.bookTypes.bookImage" />
          <ImageInput
            source="book_images"
            label=""
            accept="image/*"
            multiple
            onChange={(e) => uploadBookImages(e)}
          >
            <ImageField source="src" title="title" />
          </ImageInput>

          <Separator />

          <SectionTitle label="resources.bookTypes.benifits" />
          <ArrayInput source="book_benifits" fullWidth>
            <SimpleFormIterator
              inline
              getItemLabel={(index) => `#${index + 1}`}
            >
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => (
                  <ImageInput
                    // source={getSource("img")}
                    source="img"
                    label="Benifit Icon"
                    accept="image/*"
                    onChange={(e) => uploadImage(e, formData)}
                    {...rest}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                )}
              </FormDataConsumer>

              <TextInput
                multiline
                source="benifit"
                fullWidth
                helperText={false}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default BookManageCreate
