import AdminUserIcon from "@mui/icons-material/People"

import AppUserList from "./AppUserList"
import AppUserEdit from "./AppUserEdit"

export default {
  list: AppUserList,
  edit: AppUserEdit,
  icon: AdminUserIcon,
}
