import StoreIcon from "@mui/icons-material/Storefront"
import StoreList from "./StoreList"
import StoreCreate from "./StoreCreate"
import StoreEdit from "./StoreEdit"

export default {
  list: StoreList,
  create: StoreCreate,
  edit: StoreEdit,
  icon: StoreIcon,
}
