import * as React from "react"
import {
  Datagrid,
  List,
  TextField,
  Pagination,
  EditButton,
  DateField,
} from "react-admin"
import { Theme, useMediaQuery } from "@mui/material"

import MobileGrid from "../visitors/MobileGrid"
import MycustomeDeleteButton from "./MycustomeDeleteButton"

const StoreList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))
  return (
    <>
      <div style={{ height: "30px" }}> </div>
      <List
        sort={{ field: "firstName", order: "ASC" }}
        perPage={10}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid
            optimized
            rowClick="edit"
            sx={{
              "& .column-groups": {
                md: { display: "table-cell" },
                lg: { display: "table-cell" },
              },
            }}
          >
            <TextField source="title" label="Title" sortable={false} />
            <DateField source="createdAt" showTime />

            <EditButton />

            <MycustomeDeleteButton />
          </Datagrid>
        )}
      </List>
    </>
  )
}

export default StoreList
