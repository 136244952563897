import * as React from "react"
import { Datagrid, List, TextField, EditButton } from "react-admin"

const StoreList = () => {
  return (
    <>
      <div style={{ height: "15px" }}> </div>

      <List exporter={false}>
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "table-cell" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField
            source="shipping_charge"
            label="Shipping Charge"
            sortable={false}
          />
          <TextField
            source="phone"
            label="Store Phone Number"
            sortable={false}
          />
          <TextField
            source="email"
            label="Store Email Address"
            sortable={false}
          />
          <TextField
            source="pick_up_address"
            label="Pick Up Address"
            sortable={false}
          />

          <EditButton />
        </Datagrid>
      </List>
    </>
  )
}

export default StoreList
