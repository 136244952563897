import * as React from "react"
import { Admin, Resource, fetchUtils } from "react-admin"
import polyglotI18nProvider from "ra-i18n-polyglot"

import authProvider from "./authProvider"
import { Login, Layout } from "./layout"
import { Dashboard } from "./dashboard"
import englishMessages from "./i18n/en"
import { lightTheme } from "./layout/themes"
import orders from "./orders"
import invoices from "./invoices"
import stores from "./stores"
import appUsers from "./appUsers"
import simpleRestProvider from "ra-data-simple-rest"
import bookTypes from "./bookTypes"
import bookManager from "./bookManager"
import config from "./config/config"
import pages from "./pages"
import promoCodes from "./promoCodes"
import settings from "./settings"
const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default)
  }
  return englishMessages
}, "en")

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  // add your own headers here
  const token = localStorage.getItem("token")
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`)
  }
  return fetchUtils.fetchJson(url, options)
}

const apiUrl = config.API_URL

const dataProvider = simpleRestProvider(apiUrl, httpClient)
const isStore = localStorage.getItem("isStore")

const App = () => {
  return (
    <Admin
      title="Bunny Book"
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      theme={lightTheme}
    >
      <Resource name="orders" {...orders} options={{ label: "Orders" }} />
      <Resource name="invoices" {...invoices} />
      <Resource name="app-users" {...appUsers} />
      <Resource name="book-types" {...bookTypes} />
      <Resource name="book-manager" {...bookManager} />
      {isStore !== "true" && (
        <>
          <Resource name="stores" {...stores} />
          <Resource name="pages" {...pages} />
          <Resource name="promo-codes" {...promoCodes} />
          <Resource name="settings" {...settings} />
        </>
      )}
    </Admin>
  )
}

export default App
