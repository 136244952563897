import * as React from "react"
import { DeleteWithConfirmButton, useRecordContext } from "react-admin"

import { Store } from "../types"

const MycustomeDeleteButton = () => {
  const record = useRecordContext<Store>()
  if (!record) {
    return null
  }
  return (
    <DeleteWithConfirmButton
      label="Delete"
      confirmTitle={`Delete  ${record.store_name}`}
    />
  )
}

export default MycustomeDeleteButton
