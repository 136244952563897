import { Box, Theme, Typography, useMediaQuery } from "@mui/material"
import {
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  ImageField,
  List,
  NumberField,
  ShowButton,
  TextField,
} from "react-admin"
import BookCoverPdf from "./BookCoverPdf"
import MobileGrid from "./MobileGrid"
import OrderLinkField from "./OrderLinkField"
import UserLinkField from "./UserLinkField"

const orderFilters = [
  <DateInput source="date_gte" alwaysOn />,
  <DateInput source="date_lte" alwaysOn />,
]

const Empty = () => (
  <Box
    textAlign="center"
    className="empty-block"
    style={{ width: "100%" }}
    m={1}
  >
    <Typography variant="h4" paragraph>
      No Order available
    </Typography>
    <Typography variant="body1">Create order from App</Typography>
  </Box>
)

const OrderList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <List
        empty={<Empty />}
        sort={{ field: "date", order: "DESC" }}
        perPage={10}
        filters={orderFilters}
        sx={{
          "& tr": {
            height: 100,
          },
        }}
      >
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid optimized>
            <DateField source="order_date" />
            <UserLinkField label="User Name" sortable={false} />
            <TextField
              source="storeData.store_name"
              label="Store Name"
              sortable={false}
            />
            <ImageField
              sx={{
                "& img": {
                  maxWidth: 100,
                  maxHeight: 100,
                  objectFit: "contain",
                },
              }}
              source="bookData.book_cover_url"
              label="Book Cover Image"
              sortable={false}
            />
            <TextField
              source="order_number"
              label="Order ID"
              sortable={false}
            />
            <TextField
              source="charge_data[0].id"
              label="Transaction Id"
              sortable={false}
            />
            <TextField source="book_qty" label="Quantity" />
            <TextField source="book_type" label="Book type" />
            {/* <TextField
              source="book_cover_type.cover_book_title"
              label="Book Cover type"
            /> */}
            <TextField source="book_size" label="Book Size" />
            <NumberField
              source="total_amount"
              options={{ style: "currency", currency: "USD" }}
            />
            <TextField source="order_status" sortable={false} />
            <OrderLinkField label="Book PDF URL" sortable={false} />
            <BookCoverPdf label="Book Cover URL" sortable={false} />
            {/* <ShowButton label="View" />; */}
            <EditButton label="view" />
            <ShowButton label="Travel Sheet" />;
          </Datagrid>
        )}
      </List>
    </>
  )
}

export default OrderList
