import React, { useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  BooleanInput,
  TimeInput,
  useCreate,
  useRedirect,
} from "react-admin"
import { Box, Typography } from "@mui/material"
import { useNotify } from "react-admin"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.store_name) {
    errors.store_name = "ra.validation.required"
  }

  if (!values.store_address) {
    errors.store_address = "ra.validation.required"
  } else if (
    values.confirm_password &&
    values.password &&
    values.confirm_password !== values.password
  ) {
    errors.confirmPassword = "Password mismatched"
  }

  return errors
}

import PlaceComponent from "./PlaceComponent"

const StoreCreate = () => {
  const [street, setStreet] = useState("")
  const [create, { isLoading, error, isSuccess }] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  // AIzaSyB62babLSclQNADJZe7K6avWc9CAx8I33A

  const StoreSave = async (data: any) => {
    const params = { ...data, ...{ street: street } }
    console.log(data, "formdata", params)
    create(
      `stores`,
      {
        data: data,
      },
      {
        onSuccess: (response) => {
          notify("Store created Successfully!")
          redirect("/stores")
        },
        onError: (error: any) => {
          console.log(error, "store-create-error")
          notify(`Store Create error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Create redirect="show">
        <SimpleForm
          sx={{ maxWidth: 600 }}
          defaultValues={{
            store_name: "",
          }}
          validate={validateForm}
          onSubmit={StoreSave}
        >
          <SectionTitle label="resources.stores.create" />
          <TextInput type="text" source="store_name" isRequired fullWidth />
          <TextInput type="email" source="store_email" isRequired fullWidth />
          <Separator />
          <Box display={{ xs: "block", sm: "flex" }}>
            <BooleanInput source="is_shipping" />
            <BooleanInput source="is_pickup" />
          </Box>
          <Separator />
          <Typography color="red" fontSize={12}>
            Note : Store open and close time for the day
          </Typography>
          <Box
            display={{ xs: "block", sm: "flex" }}
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <TimeInput
              source="store_open"
              label="Store Open Time"
              sx={{ width: "48%", display: "flex" }}
            />
            <TimeInput
              source="store_close"
              label="Store Close Time"
              sx={{ width: "48%", display: "flex" }}
            />
          </Box>
          <Separator />
          <SectionTitle label="resources.stores.address" />
          <TextInput
            source="store_address"
            isRequired
            multiline
            fullWidth
            helperText={false}
          />
          <TextInput
            source="zip_code"
            isRequired
            fullWidth
            helperText={false}
          />

          <PlaceComponent
            source="street"
            label="Street"
            setStreet={setStreet}
          />
        </SimpleForm>
      </Create>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default StoreCreate
