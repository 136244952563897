import * as React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"

import {
  useTranslate,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin"

import orders from "../orders"
import invoices from "../invoices"
import stores from "../stores"
import appUsers from "../appUsers"
import bookTypes from "../bookTypes"
import pages from "../pages"
import settings from "../settings"
import promoCodes from "../promoCodes"

type MenuName = "menuBook" | "menuSales" | "menuCustomers"

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuBook: true,
    menuSales: true,
    menuCustomers: true,
  })
  const translate = useTranslate()
  const [open] = useSidebarState()

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }
  const isStore = localStorage.getItem("isStore")

  return (
    <>
      <div style={{ height: "60px" }}> </div>

      <Box
        sx={{
          width: open ? 200 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <MenuItemLink
          to="/"
          state={{ _scrollToTop: true }}
          primaryText={"Dashboard"}
          leftIcon={<stores.icon />}
          dense={dense}
        />
        {isStore !== "true" && (
          <MenuItemLink
            to="/app-users"
            state={{ _scrollToTop: true }}
            primaryText={"App Users"}
            leftIcon={<appUsers.icon />}
            dense={dense}
          />
        )}

        {isStore !== "true" && (
          <MenuItemLink
            to="/book-types"
            state={{ _scrollToTop: true }}
            primaryText={"Book Type"}
            leftIcon={<bookTypes.icon />}
            dense={dense}
          />
        )}

        <MenuItemLink
          to="/orders"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.commands.name`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invoices"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        />

        {isStore !== "true" && (
          <>
            <MenuItemLink
              to="/stores"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.stores.name`, {
                smart_count: 2,
              })}
              leftIcon={<stores.icon />}
              dense={dense}
            />
          </>
        )}
        {isStore !== "true" && (
          <>
            <MenuItemLink
              to="/pages"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.pages.name`, {
                smart_count: 2,
              })}
              leftIcon={<pages.icon />}
              dense={dense}
            />
          </>
        )}
        {isStore !== "true" && (
          <>
            <MenuItemLink
              to="/promo-codes"
              state={{ _scrollToTop: true }}
              primaryText={"Promo Codes"}
              leftIcon={<promoCodes.icon />}
              dense={dense}
            />
          </>
        )}
        {isStore !== "true" && (
          <>
            <MenuItemLink
              to="/settings"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.setting.name`, {
                smart_count: 2,
              })}
              leftIcon={<settings.icon />}
              dense={dense}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default Menu
