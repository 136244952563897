import * as React from "react"
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useTranslate, useReference } from "react-admin"

import { Customer, Order } from "../types"

interface Props {
  order: Order
}

export const PendingOrder = (props: Props) => {
  const translate = useTranslate()
  const { order } = props
  const customer = order.userData

  return (
    <ListItem button component={Link} to={`/orders/${order.id}`}>
      <ListItemAvatar>
        <Avatar
          src={`${order.bookData.book_cover_url}?size=32x32`}
          sx={{
            bgcolor: "background.paper",
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={new Date(order.order_date).toLocaleString("en-US")}
        secondary={translate("pos.dashboard.order.items", {
          smart_count: order.book_qty,
          nb_items: order.book_qty,
          customer_name: customer ? `${customer.first_name} ` : "",
          store_Name: `${order.storeData.store_name} `,
        })}
      />
      <ListItemSecondaryAction>
        <Box
          component="span"
          sx={{
            marginRight: "1em",
            color: "text.primary",
          }}
        >
          {order.total_amount.toLocaleString(undefined, {
            style: "currency",
            currency: "USD",
          })}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
