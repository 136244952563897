import * as React from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  useUpdate,
  useRecordContext,
  useNotify,
  useRedirect,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"

const PageEdit = () => {
  const redirect = useRedirect()

  const [update] = useUpdate()
  const record = useRecordContext()
  const notify = useNotify()
  const saveItem = async (data: any) => {
    update(
      `settings`,
      {
        id: data.id,
        data,
        previousData: record,
      },
      {
        onSuccess: (data: any) => {
          // success side effects go here
          redirect("/settings")
          notify("Setting Updated")
        },
        onError: (error: any) => {
          // failure side effects go here
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <Edit title={<PageTitle />}>
      <Separator />

      <SimpleForm onSubmit={saveItem} noValidate>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {"Settings"}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput type="text" source="shipping_charge" fullWidth />
              </Box>
              <Separator />

              <SectionTitle label="resources.setting.detail" />
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput
                  type="email"
                  source="email"
                  label="Store Email Address"
                  fullWidth
                />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput
                  type="text"
                  source="phone"
                  label="Store Phone Number"
                  fullWidth
                />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput type="textarea" source="pick_up_address" fullWidth />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

const PageTitle = () => (
  <Typography
    variant="body2"
    display="flex"
    flexWrap="nowrap"
    alignItems="center"
    component="div"
    sx={{ margin: "5px 0" }}
  >
    Settings
  </Typography>
)

export default PageEdit
