import * as React from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  useUpdate,
  useRecordContext,
  useNotify,
  useRedirect,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"
import { validateForm } from "./PageCreate"
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text"

const PageEdit = () => {
  const redirect = useRedirect()

  const [update] = useUpdate()
  const record = useRecordContext()
  const notify = useNotify()
  const storeSave = async (data: any) => {
    update(
      `pages`,
      {
        id: data.id,
        data,
        previousData: record,
      },
      {
        onSuccess: (data) => {
          // success side effects go here
          redirect("/pages")
          notify("Page Updated")
        },
        onError: (error: any) => {
          // failure side effects go here
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <Edit title={"Edit Page"}>
      <Separator />

      <SimpleForm validate={validateForm} onSubmit={storeSave}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {"Edit Page"}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput type="text" source="title" isRequired fullWidth />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput type="text" source="slug" isRequired fullWidth />
              </Box>
              <Separator />
              <SectionTitle label="resources.pages.content" />
              <RichTextInput
                source="content"
                fullWidth
                minHeight={300}
                toolbar={<RichTextInputToolbar size="large" />}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default PageEdit
