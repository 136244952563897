import DiscountIcon from "@mui/icons-material/Discount"
import PromoCodeList from "./PromoCodeList"
import PromoCodeCreate from "./PromoCodeCreate"
import PromoCodeEdit from "./PromoCodeEdit"

export default {
  list: PromoCodeList,
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  icon: DiscountIcon,
}
