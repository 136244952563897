import React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  useCreate,
  useRedirect,
  NumberInput,
  DateTimeInput,
  RadioButtonGroupInput,
} from "react-admin"
import { Box, Typography } from "@mui/material"
import { useNotify } from "react-admin"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.name) {
    errors.name = "ra.validation.required"
  }

  if (!values.type) {
    errors.type = "ra.validation.required"
  }

  if (!values.promo_code) {
    errors.promo_code = "ra.validation.required"
  }

  if (!values.starts_at) {
    errors.starts_at = "ra.validation.required"
  }

  if (!values.expires_at) {
    errors.expires_at = "ra.validation.required"
  }

  return errors
}

const PromoCodeCreate = () => {
  const [create, { isLoading, error, isSuccess }] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSave = async (data: any) => {
    const params = { ...data }
    create(
      `promo-codes`,
      {
        data: data,
      },
      {
        onSuccess: (response) => {
          notify("Promo code created Successfully!")
          redirect("/promo-codes")
        },
        onError: (error: any) => {
          console.log(error, "promo-code-create-error")
          notify(`Create error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Create redirect="show">
        <SimpleForm
          sx={{ maxWidth: 600 }}
          defaultValues={{
            name: "",
          }}
          validate={validateForm}
          onSubmit={onSave}
        >
          <SectionTitle label="resources.promoCode.create" />
          <TextInput type="text" source="name" isRequired fullWidth />
          <RadioButtonGroupInput
            source="type"
            choices={[
              { id: 1, name: "Percentage" },
              { id: 2, name: "Flat" },
            ]}
          />
          <Box
            display={{ xs: "block", sm: "flex" }}
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <NumberInput
              sx={{ width: "50%", display: "flex" }}
              type="text"
              source="amount"
              label="Enter Amount / Percentage"
              isRequired
              fullWidth
            />
          </Box>
          <TextInput type="text" source="promo_code" isRequired fullWidth />
          <Separator />
          <Box
            display={{ xs: "block", sm: "flex" }}
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <DateTimeInput
              source="starts_at"
              sx={{ width: "48%", display: "flex" }}
            />

            <DateTimeInput
              source="expires_at"
              sx={{ width: "48%", display: "flex" }}
            />
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default PromoCodeCreate
