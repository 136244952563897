import * as React from "react"
import {
  Datagrid,
  List,
  TextField,
  Pagination,
  EditButton,
  DateField,
} from "react-admin"

import MycustomeDeleteButton from "./MycustomeDeleteButton"

const PromoCodeList = () => {
  return (
    <>
      <div style={{ height: "30px" }}> </div>
      <List
        sort={{ field: "firstName", order: "ASC" }}
        perPage={10}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
      >
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "table-cell" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="name" sortable={false} />
          <TextField source="promo_code" label="Promo code" sortable={false} />
          <DateField source="starts_at" showTime />
          <DateField source="expires_at" showTime />
          <DateField source="created_at" showTime />

          <EditButton />

          <MycustomeDeleteButton />
        </Datagrid>
      </List>
    </>
  )
}

export default PromoCodeList
