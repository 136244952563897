import * as React from "react"
import { SxProps, Typography } from "@mui/material"
import { memo } from "react"

import { FieldProps, useRecordContext } from "react-admin"
import { Store } from "../types"

interface Props extends FieldProps<Store> {
  size?: string
  sx?: SxProps
}

const FullNameField = (props: Props) => {
  const { size } = props
  const record = useRecordContext<Store>()
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      {record.store_name}
    </Typography>
  ) : null
}

FullNameField.defaultProps = {
  source: "last_name",
  label: "resources.stores.fields.store_name",
}

export default memo<Props>(FullNameField)
