import React, { useEffect, useRef, useState } from "react"
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete"
import { useInput } from "react-admin"
import config from "../config/config"

// auto complete component
const PlaceComponent = ({ source, label, setStreet }: any) => {
  const { id, field, fieldState } = useInput({ source })
  const { ref: inputRef }: any = usePlacesWidget({
    apiKey: config.AUTOCOMPLETE_KEY,
    onPlaceSelected: (place: any) => {
      console.log(place, "place", place.formatted_address)
      setStreet(place.formatted_address)
    },
  })

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        background: "whitesmoke",
        height: "40px",
        borderBottom: "1px solid",
      }}
      className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth MuiTextField-root ra-input ra-input-street place-autocomplete-input"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "inherit",
        }}
        className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall"
      >
        <input
          style={{
            width: "100%",
            display: "flex",
            height: "inherit",
            background: "transparent",
            border: "none",
            textIndent: "15px",
            outline: "none",
            font: "inherit",
            letterSpacing: "inherit",
            color: "inherit",
          }}
          ref={inputRef}
          id="street"
          name="street"
          type="text"
          className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputSizeSmall"
        />
      </div>
    </div>
  )
}

export default PlaceComponent
