import * as React from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  DateInput,
} from "react-admin"

import FullNameField from "../visitors/FullNameField"
import AddressField from "../visitors/AddressField"
import InvoiceShow from "./InvoiceShow"
import { Box, Button, Typography } from "@mui/material"
import UserLinkField from "../orders/UserLinkField"

const listFilters = [
  <DateInput source="date_gte" alwaysOn />,
  <DateInput source="date_lte" alwaysOn />,
]

const Empty = () => (
  <Box
    textAlign="center"
    className="empty-block"
    style={{ width: "100%" }}
    m={1}
  >
    <Typography variant="h4" paragraph>
      No Order available
    </Typography>
    <Typography variant="body1">Create order from App</Typography>
    {/* <CreateButton /> */}
  </Box>
)

const InvoiceList = () => {
  return (
    <>
      <div style={{ height: "60px" }}> </div>

      <List
        empty={<Empty />}
        filters={listFilters}
        perPage={10}
        sort={{ field: "date", order: "desc" }}
      >
        <Datagrid
          rowClick="expand"
          expand={<InvoiceShow />}
          sx={{
            "& .column-customer_id": {
              display: { xs: "none", md: "table-cell" },
            },
            "& .column-total_ex_taxes": {
              display: { xs: "none", md: "table-cell" },
            },
            "& .column-delivery_fees": {
              display: { xs: "none", md: "table-cell" },
            },
            "& .column-taxes": {
              display: { xs: "none", md: "table-cell" },
            },
          }}
        >
          {/* <TextField source="id" /> */}
          <DateField source="order_date" label="Invoice Date" />
          <UserLinkField label="User Name" sortable={false} />
          <TextField label="resources.invoices.fields.address" sortable={false}>
            <AddressField />
          </TextField>
          <TextField source="order_number" label="Order ID" sortable={false} />
          <TextField source="book_type" label="Book type" />
          {/* <TextField
            source="book_cover_type.cover_book_title"
            label="Book Cover type"
          /> */}
          <TextField source="book_size" label="Book Size" />
          <NumberField
            source="price"
            label="Prices"
            options={{ style: "currency", currency: "USD" }}
            sortable={false}
          />
          {/* <NumberField source="taxes" sortable={false} /> */}
          <NumberField
            source="total_amount"
            options={{ style: "currency", currency: "USD" }}
          />
        </Datagrid>
      </List>
    </>
  )
}

export default InvoiceList
