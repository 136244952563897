import * as React from "react"
import {
  CreateButton,
  DeleteWithConfirmButton,
  Pagination,
  List,
} from "react-admin"
import { Typography, Box } from "@mui/material"
import { Datagrid, TextField, ImageField } from "react-admin"

const Empty = () => (
  <Box
    textAlign="center"
    className="empty-block"
    style={{ width: "100%" }}
    m={1}
  >
    <Typography variant="h4" paragraph>
      No Record found
    </Typography>
    <Typography variant="body1">Click to Create Item </Typography>
    <CreateButton />
  </Box>
)

const BookManagerList = () => (
  <>
    {/* <div style={{ height: "60px" }}> </div> */}
    <List
      // sort={{ field: "name", order: "ASC" }}
      perPage={10}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
      component="div"
      empty={<Empty />}
    >
      <Datagrid
        optimized
        rowClick="edit"
        sx={{
          "& .column-groups": {
            md: { display: "table-cell" },
            lg: { display: "table-cell" },
          },
        }}
      >
        <ImageField
          sx={{
            "& img": {
              maxWidth: 100,
              maxHeight: 100,
              objectFit: "contain",
            },
          }}
          source="display_image"
          label="Book Image"
        />
        <TextField source="book_type" label="Book Type" />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  </>
)

export default BookManagerList
