import * as React from "react"
import { FieldProps, useRecordContext } from "react-admin"
import { Typography } from "@mui/material"

import axios from "axios"

import { Order } from "../types"
import config from "../config/config"

const BookCoverPdf = (props: FieldProps<Order>) => {
  const record = useRecordContext<Order>()

  const downloadPdf = () => {
    const apiUrl = config.API_URL
    window.open(record.bookData.soft_cover_pdf_url, "_blank")
    // if (record && record.bookData && record.bookData.book_type === 2) {
    //   window.open(record.bookData.soft_cover_pdf_url, "_blank")
    // } else if (record && record.bookData && record.bookData.book_cover_url) {
    //   const order_number = record.order_number
    //   const store_name = record.bookData.store_id.store_name || "demoStore"
    //   axios
    //     .post(`${apiUrl}/get-cover-pdf`, {
    //       url: record.bookData.book_cover_url,
    //       order_number: order_number,
    //       store_name: store_name,
    //     })
    //     .then(function (response) {
    //       window.open(response.data.data, "_blank")
    //     })
    //     .catch(function (err) {
    //       console.log(err)
    //     })
    // }
  }

  if (!record.bookData || !record.bookData?.book_cover_url) {
    return (
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      >
        ---
      </Typography>
    )
  }
  const size = 100
  return (
    <a
      // href={}
      // href={record.bookData?.book_cover_url}
      onClick={() => downloadPdf()}
      target="_blank"
    >
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
          cursor: "pointer",
        }}
      >
        Download
      </Typography>
    </a>
  )
}

BookCoverPdf.defaultProps = {
  source: "order_number",
}

export default BookCoverPdf
