import PageIcon from "@mui/icons-material/PagesOutlined"
import PageList from "./PageList"
import PageCreate from "./PageCreate"
import PageEdit from "./PageEdit"

export default {
  list: PageList,
  create: PageCreate,
  edit: PageEdit,
  icon: PageIcon,
}
