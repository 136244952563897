import * as React from "react"
import { useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  ImageField,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  FormDataConsumer,
  SaveButton,
  Toolbar,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import axios from "axios"
import Button from "@mui/material/Button"
import config from "../config/config"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.cover_book_title) {
    errors.cover_book_title = "ra.validation.required"
  }

  return errors
}

const BookTypeCreate = () => {
  const [file, setFile] = useState<any>({})
  const [bookImages, setBookImages] = useState<any>([])
  const [uploadingInprogress, setUploadingInprogress] = useState<boolean>(false)

  const uploadImage = (event: any) => {
    const apiUrl = config.API_URL
    if (typeof event === "object" && event.path) {
      let file = event
      let formData = new FormData()
      formData.append("file", file)
      setUploadingInprogress(true)
      axios
        .post(`${apiUrl}/upload`, formData)
        .then(function (response) {
          setUploadingInprogress(false)
          setFile(response.data.data)
        })
        .catch(function (err) {
          console.log(err)
          setUploadingInprogress(false)
          setFile(undefined)
        })
    }
  }

  const uploadBookImages = async (files: any) => {
    const apiUrl = config.API_URL
    let formData = new FormData()
    let count = 0
    if (files.length > 0) {
      const oldItems = [] as any[]
      for (const key in files) {
        if (files[key] && files[key].path) {
          formData.append("files", files[key])
          count++
        }
        if (files[key]?.src && files[key].src !== "") {
          oldItems.push(files[key])
        }
      }
      if (count > 0) {
        setUploadingInprogress(true)
        axios
          .post(`${apiUrl}/upload-multiple`, formData)
          .then(function (response) {
            const tempItems = [...bookImages, ...response.data.data]
            setBookImages([...tempItems, ...oldItems])
            setUploadingInprogress(false)
          })
          .catch(function (err) {
            console.log(err)
            const tempItems = [...bookImages, ...oldItems]
            setBookImages(tempItems)
            setUploadingInprogress(false)
          })
      } else {
        setBookImages(files)
      }
    }
  }

  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const uploadImageLoop = async (file: any) => {
    const apiUrl = config.API_URL
    if (file) {
      let formData = new FormData()
      formData.append("file", file)
      return await axios
        .post(`${apiUrl}/upload`, formData)
        .then(function (response) {
          return response.data.data
        })
        .catch(function (err) {
          console.log(err)
          return ""
        })
    }
  }

  const itemSave = async (data: any) => {
    data.cover_book_image = file
    data.book_images = bookImages

    console.log(data, "data")

    if (!data.price2) {
      notify(`Book Size Price is Required!`, { type: "error" })
      return false
    } else if (!data.price2 && !data.price3) {
      notify(`two book Size Price is Required!`, { type: "error" })
      return false
    }

    if (data.book_benifits) {
      const tampBenifits: any = []
      await data.book_benifits.forEach(async (item: any) => {
        const tempItem = { ...item }
        if (item.icon.rawFile) {
          const link = await uploadImageLoop(item.icon.rawFile)
          tempItem.icon = { src: link }
          tampBenifits.push(tempItem)
        } else {
          tampBenifits.push(tempItem)
        }
      })
      data.book_benifits = tampBenifits
    }
    setTimeout(() => {
      createItem(data)
    }, 2000)
  }

  const createItem = async (data: any) => {
    await create(
      `book-types`,
      {
        data: data,
      },
      {
        onSuccess: (response) => {
          redirect("/book-types")
          notify("Book Type Saved")
        },
        onError: (error: any) => {
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  const updateBookType = (e: any) => {
    console.log("updateBookType", e)
  }

  const MySaveButton = () => (
    <Toolbar>
      <SaveButton disabled={uploadingInprogress} />
    </Toolbar>
  )

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Create redirect="show">
        <SimpleForm
          sx={{ maxWidth: 600 }}
          defaultValues={{
            cover_book_title: "",
            cover_book_type: 1,
            order: 0,
            book_images: [],
            book_benifits: [
              {
                icon: "",
                benifit: "",
              },
            ],
          }}
          validate={validateForm}
          onSubmit={itemSave}
          toolbar={<MySaveButton />}
        >
          <div>
            <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {"Create Book Type"}
                    </Typography>

                    <Box display={{ xs: "block", sm: "flex" }}>
                      <TextInput
                        type="text"
                        source="cover_book_title"
                        isRequired
                        fullWidth
                      />
                    </Box>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <SelectInput
                        source="cover_book_type"
                        emptyText="Hard Cover"
                        emptyValue={1}
                        onChange={updateBookType}
                        choices={[
                          {
                            id: 2,
                            name: "Soft Cover",
                          },
                          {
                            id: 3,
                            name: "Save Book Cover",
                          },
                        ]}
                      />
                    </Box>

                    {formData && [1, 2].includes(formData.cover_book_type) && (
                      <>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box sx={{ display: "flex", marginRight: "15px" }}>
                            <TextInput
                              source="book_size2"
                              label="book_size"
                              disabled
                              defaultValue="Book Size 8*8"
                            />
                          </Box>
                          <Box sx={{ display: "flex", maxWidth: "50%" }}>
                            <TextInput source="price2" label="Price" />
                          </Box>
                        </Box>
                        {formData && formData.cover_book_type !== 1 && (
                          <Box display={{ xs: "block", sm: "flex" }}>
                            <Box sx={{ display: "flex", marginRight: "15px" }}>
                              <TextInput
                                source="book_size1"
                                label="book_size"
                                disabled
                                defaultValue="Book Size 5*7"
                              />
                            </Box>
                            <Box sx={{ display: "flex", maxWidth: "50%" }}>
                              <TextInput source="price1" label="Price" />
                            </Box>
                          </Box>
                        )}
                        {formData && formData.cover_book_type === 1 && (
                          <Box display={{ xs: "block", sm: "flex" }}>
                            <Box sx={{ display: "flex", marginRight: "15px" }}>
                              <TextInput
                                source="book_size3"
                                label="book_size"
                                disabled
                                defaultValue="Book Size 8*11"
                              />
                            </Box>
                            <Box sx={{ display: "flex", maxWidth: "50%" }}>
                              <TextInput source="price3" label="Price" />
                            </Box>
                          </Box>
                        )}
                        {/* <ArrayInput
                          source="book_sizes"
                          fullWidth
                        >
                          <SimpleFormIterator
                            inline
                            disableRemove={true}
                            disableAdd={
                              formData.book_sizes &&
                              formData.book_sizes.length > 1
                            }
                            addButton={
                              <Button color="secondary">
                                Add Book Type
                                <AddIcon />
                              </Button>
                            }
                          >
                            <SelectInput
                              source="book_size"
                              emptyText="Book Size 8*8"
                              emptyValue={2}
                              defaultValue={2}
                              choices={[
                                {
                                  id:
                                    formData && formData.cover_book_type == 1
                                      ? 3
                                      : 1,
                                  name:
                                    formData && formData.cover_book_type == 1
                                      ? "Book Size 8*11"
                                      : "Book Size 5*7",
                                },
                              ]}
                              {...rest}
                            />

                            <TextInput
                              source="price"
                              autoFocus
                              helperText={false}
                            />
                          </SimpleFormIterator>
                        </ArrayInput> */}

                        <SectionTitle label="resources.bookTypes.coverImage" />

                        <ImageInput
                          source="cover_book_image"
                          label="Cover / Display Image"
                          accept="image/*"
                          onChange={(e) => uploadImage(e)}
                          sx={{
                            background: "#f5f5f5",
                            "& .RaFileInput-dropZone": {
                              background: "#f5f5f5",
                            },
                          }}
                        >
                          <ImageField source="src" title="title" />
                        </ImageInput>
                        <Separator />
                        <Separator />
                        <SectionTitle label="resources.bookTypes.bookImage" />
                        <ImageInput
                          source="book_images"
                          label="Book Images"
                          accept="image/*"
                          multiple
                          onChange={(e) => uploadBookImages(e)}
                          sx={{
                            background: "#f5f5f5",
                            "& .RaFileInput-dropZone": {
                              background: "#f5f5f5",
                            },
                          }}
                        >
                          <ImageField source="src" title="title" />
                        </ImageInput>
                        <Separator />

                        <SectionTitle label="resources.bookTypes.benifits" />

                        <ArrayInput source="book_benifits" fullWidth>
                          <SimpleFormIterator
                            inline
                            getItemLabel={(index) => `#${index + 1} Benefit`}
                            addButton={
                              <Button color="secondary">
                                Add Benefit <AddIcon />
                              </Button>
                            }
                          >
                            <TextInput
                              multiline
                              source="benifit"
                              fullWidth
                              autoFocus
                              helperText={false}
                            />
                            <Separator />
                            <ImageInput
                              source={"icon"}
                              label="Benefit Icon"
                              accept="image/*"
                              sx={{
                                background: "#f5f5f5",
                                "& .RaFileInput-dropZone": {
                                  background: "#f5f5f5",
                                },
                              }}
                            >
                              <ImageField
                                source="src"
                                sx={{
                                  width: "100%",
                                  "& .RaImageField-image": {
                                    width: "100%",
                                  },
                                }}
                              />
                            </ImageInput>
                            <hr />
                            <Separator />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </>
                    )}
                  </Grid>
                )}
              </FormDataConsumer>
            </Grid>
          </div>
        </SimpleForm>
      </Create>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default BookTypeCreate
