import * as React from "react"
import { useState } from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  ImageField,
  BooleanInput,
  FileInput,
  useUpdate,
  useRecordContext,
  useNotify,
  useRedirect,
  PasswordInput,
  AutocompleteInput,
  TimeInput,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"
import FullNameField from "./FullNameField"
import { validateForm } from "./StoreCreate"
import axios from "axios"
import config from "../config/config"
import PlaceComponent from "./PlaceComponent"

const StoreEdit = () => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const [street, setStreet] = useState("")
  const [file, setFile] = useState(undefined)
  const uploadImage = (event: any) => {
    const apiUrl = config.API_URL
    if (event && event.target && event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      let formData = new FormData()
      formData.append("file", file)
      axios
        .post(`${apiUrl}/upload`, formData)
        .then(function (response) {
          console.log(response.data, "response.data.data", file)
          setFile(response.data.data)
        })
        .catch(function (err) {
          //handle error
          console.log(err)
          setFile(undefined)
        })
    }
  }

  const [update] = useUpdate()
  const record = useRecordContext()
  const notify = useNotify()
  const storeSave = async (data: any) => {
    const formData = { ...data }
    formData.store_logo = file
    update(
      `stores`,
      {
        id: data.id,
        data: formData,
        previousData: record,
      },
      {
        onSuccess: (data: any) => {
          // success side effects go here
          redirect("/stores")
          notify("Store Updated")
        },
        onError: (error: any) => {
          // failure side effects go here
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <Edit title={<StoreTitle />}>
      <Separator />
      <Separator />
      <SimpleForm validate={validateForm} onSubmit={storeSave}>
        <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {"Edit Store"}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <TextInput type="text" source="store_name" isRequired fullWidth />
            </Box>
            <Separator />
            <SectionTitle label="resources.stores.address" />
            <TextInput
              source="store_address"
              isRequired
              multiline
              fullWidth
              helperText={false}
            />
            <TextInput
              source="zip_code"
              isRequired
              fullWidth
              helperText={false}
            />
            <PlaceComponent
              source="street"
              label="Street"
              setStreet={setStreet}
            />

            <Separator />
            <Box display={{ xs: "block", sm: "flex" }}>
              <BooleanInput source="is_shipping" />
              <BooleanInput source="is_pickup" />
            </Box>
            <Separator />
            <Typography color="red" fontSize={12}>
              Note : Store open and close time for the day
            </Typography>
            <Box
              display={{ xs: "block", sm: "flex" }}
              sx={{ justifyContent: "space-between" }}
            >
              <TimeInput
                source="store_open"
                label="Store Open Time"
                sx={{ minWidth: "48%" }}
              />
              <TimeInput
                source="store_close"
                label="Store Close Time"
                sx={{ minWidth: "48%" }}
              />
            </Box>

            <Separator />
            <SectionTitle label="resources.stores.logo" />
            <FileInput
              source="store_logo"
              label=""
              accept="image/*"
              onChange={(e: Event) => uploadImage(e)}
              sx={{
                background: "#f5f5f5",
                "& .RaFileInput-dropZone": {
                  background: "#f5f5f5",
                },
              }}
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <Separator />
            <Box display={{ xs: "block", sm: "flex" }}>
              <PasswordInput source="password" fullWidth />
            </Box>
            {/* <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <PasswordInput source="confirm_password" fullWidth />
              </Box> */}
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}
const Separator = () => <Box pt="1em" />

const StoreTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />

export default StoreEdit
