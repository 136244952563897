import * as React from "react"
import { useState } from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  ImageField,
  FileInput,
  useUpdate,
  useRecordContext,
  useNotify,
  useRedirect,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"

import axios from "axios"
import config from "../config/config"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.cover_book_title) {
    errors.cover_book_title = "ra.validation.required"
  }

  if (!values.cover_book_price) {
    errors.cover_book_price = "ra.validation.required"
  }

  return errors
}

const BookManagerEdit = () => {
  const redirect = useRedirect()

  const [file, setFile] = useState(undefined)
  const [bookImages, setBookImages] = useState(undefined)
  const [bookBenifits, setBookBenifits] = useState([{ img: "", benifit: "" }])

  const uploadImage = (event: any, type: string) => {
    const apiUrl = config.API_URL
    if (event && event.target && event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      let formData = new FormData()
      formData.append("file", file)
      axios
        .post(`${apiUrl}/upload`, formData)
        .then(function (response) {
          if (type === "benifit") {
            const tempBookBenifits = [...bookBenifits]
            tempBookBenifits[0].img = response.data.data
            setBookBenifits(tempBookBenifits)
          }
          if (type === "mainImage") {
            setFile(response.data.data)
          }
        })
        .catch(function (err) {
          console.log(err)
          setFile(undefined)
        })
    }
  }

  const uploadBookImages = (event: any) => {
    const apiUrl = config.API_URL
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const files = event.target.files
      let formData = new FormData()
      let count = 0
      for (const key in files) {
        if (count < files.length) {
          formData.append("files", files[key])
        }
        count++
      }
      axios
        .post(`${apiUrl}/upload-multiple`, formData)
        .then(function (response) {
          setBookImages(response.data.data)
        })
        .catch(function (err) {
          console.log(err)
          setBookImages(undefined)
        })
    }
  }

  const [update] = useUpdate()
  const record = useRecordContext()
  const notify = useNotify()
  const itemUpdate = async (data: any) => {
    data.display_image = file
    data.book_images = bookImages
    data.book_benifits = bookBenifits
    update(
      `book-manager`,
      {
        id: data.id,
        data,
        previousData: record,
      },
      {
        onSuccess: (data) => {
          // success side effects go here
          redirect("/book-manager")
          notify("Book Type Updated")
        },
        onError: (error: any) => {
          // failure side effects go here
          notify(`Comment approval error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  // const PreviewImage = ({ record: any, source: any }) => {
  //   console.log(record, source)
  //   if (typeof record == "string") {
  //     record = {
  //       [source]: record,
  //     }
  //   }
  //   return <ImageField record={record} source={source} />
  // }

  return (
    <Edit title={"Edit Book"}>
      <Separator />
      <Separator />
      <SimpleForm validate={validateForm} onSubmit={itemUpdate}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {"Edit Book"}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <SelectInput
                  source="book_type"
                  choices={[
                    {
                      id: 1,
                      name: "Hard Cover",
                    },
                    {
                      id: 2,
                      name: "Soft Cover",
                    },
                  ]}
                />
              </Box>
              <SectionTitle label="resources.bookTypes.coverImage" />
              <FileInput
                source="display_image"
                label=""
                accept="image/*"
                onChange={(e) => uploadImage(e, "mainImage")}
              >
                {/* <PreviewImage source="src" /> */}

                <ImageField source="src" title="title" />
              </FileInput>

              <Separator />

              <Separator />

              <SectionTitle label="resources.bookTypes.bookImage" />
              <ImageInput
                source="book_images"
                label=""
                accept="image/*"
                multiple
                onChange={(e) => uploadBookImages(e)}
              >
                <ImageField source="src" title="title" />
              </ImageInput>

              <Separator />

              <SectionTitle label="resources.bookTypes.benifits" />
              <ArrayInput source="book_benifits">
                <SimpleFormIterator fullWidth inline>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <ImageInput
                      source="book_benifits.img"
                      label="Image"
                      accept="image/*"
                      onChange={(e) => uploadImage(e, "benifit")}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <TextInput
                      multiline
                      source="book_benifits.benifit"
                      helperText={false}
                    />
                  </Box>
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}
const Separator = () => <Box pt="1em" />

// const StoreTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />

export default BookManagerEdit
