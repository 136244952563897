import * as React from "react"
import { ReferenceField, ReferenceFieldProps } from "react-admin"

import FullNameField from "./FullNameField"

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, "reference" | "children" | "source"> & {
    source?: string
  }
) => (
  <ReferenceField source="user_id" reference="users" {...props}>
    <FullNameField />
  </ReferenceField>
)

CustomerReferenceField.defaultProps = {
  source: "user_id",
}

export default CustomerReferenceField
