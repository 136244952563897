import BookTypeIcon from "@mui/icons-material/Bookmark"

import BookTypeList from "./BookTypeList"
import BookTypeEdit from "./BookTypeEdit"
import BookTypeCreate from "./BookTypeCreate"

export default {
  list: BookTypeList,
  create: BookTypeCreate,
  edit: BookTypeEdit,
  icon: BookTypeIcon,
}
