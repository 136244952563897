import * as React from "react"
import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  Pagination,
  ImageField,
  EditButton,
} from "react-admin"
import { Theme, useMediaQuery } from "@mui/material"

import MycustomeDeleteButton from "./MycustomeDeleteButton"

const StoreList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"))
  return (
    <>
      <div style={{ height: "30px" }}> </div>
      <List
        sort={{ field: "firstName", order: "ASC" }}
        perPage={10}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
      >
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "table-cell" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <ImageField
            sx={{
              "& img": {
                maxWidth: 100,
                maxHeight: 100,
                objectFit: "contain",
              },
            }}
            source="store_logo"
            sortable={false}
          />
          <TextField source="store_name" label="Store Name" sortable={false} />

          <TextField
            source="store_address"
            label="Store Address"
            sortable={false}
          />

          <BooleanField source="is_pickup" sortable={false} />

          <BooleanField source="is_shipping" sortable={false} />

          <EditButton />

          <MycustomeDeleteButton />
        </Datagrid>
      </List>
    </>
  )
}

export default StoreList
