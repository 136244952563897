import React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  useCreate,
  useRedirect,
} from "react-admin"
import { Box, Typography } from "@mui/material"
import { useNotify } from "react-admin"
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any

  if (!values.title) {
    errors.title = "ra.validation.required"
  }

  if (!values.slug) {
    errors.slug = "ra.validation.required"
  }

  return errors
}

const StoreCreate = () => {
  const [create, { isLoading, error, isSuccess }] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  const PageSave = async (data: any) => {
    const params = { ...data }
    create(
      `pages`,
      {
        data: data,
      },
      {
        onSuccess: (response) => {
          notify("Page created Successfully!")
          redirect("/pages")
        },
        onError: (error: any) => {
          console.log(error, "page-create-error")
          notify(`Page Create error: ${error.message}`, { type: "error" })
        },
      }
    )
  }

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Create redirect="show">
        <SimpleForm
          defaultValues={{
            title: "",
          }}
          validate={validateForm}
          onSubmit={PageSave}
        >
          <SectionTitle label="resources.pages.create" />
          <TextInput type="text" source="title" isRequired fullWidth />
          <TextInput type="text" source="slug" isRequired fullWidth />
          <Separator />

          <SectionTitle label="resources.pages.content" />
          <RichTextInput
            source="content"
            label=""
            fullWidth
            height="1000"
            className="my-editor"
            minHeight="300"
            toolbar={<RichTextInputToolbar size="large" />}
          />
        </SimpleForm>
      </Create>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default StoreCreate
