import * as React from "react"
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  BooleanInput,
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"
import FullNameField from "./FullNameField"

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any
  if (!values.first_name) {
    errors.first_name = "ra.validation.required"
  }
  if (!values.email) {
    errors.email = "ra.validation.required"
  }

  return errors
}

const AppUserEdit = () => {
  const translate = useTranslate()
  return (
    <Edit title={<StoreTitle />}>
      <Separator />
      <Separator />
      <SimpleForm validate={validateForm}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {"Edit App User"}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <TextInput
                  type="text"
                  source="first_name"
                  label="Name"
                  isRequired
                  fullWidth
                />
              </Box>

              <TextInput type="email" source="email" isRequired fullWidth />
              <TextInput source="phone" disabled={true} fullWidth />

              <Separator />

              <Box display={{ xs: "block", sm: "flex" }}>
                <BooleanInput source="isUserVerify" />
              </Box>

              <Separator />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}
const Separator = () => <Box pt="1em" />

const StoreTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />

export default AppUserEdit
