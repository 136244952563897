import { Box, Grid, Typography } from "@mui/material"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { useRef } from "react"
import { DateField, Edit, useRecordContext, useTranslate } from "react-admin"
import { Customer, Order } from "../types"
const OrderTravelSheet = () => {
  const record = useRecordContext<Order>()

  return (
    <Edit title={"Order details"}>
      <TravelSheet />
    </Edit>
  )
}

const CustomerAddress = () => {
  const record = useRecordContext<Customer>()
  const translate = useTranslate()

  if (!record?.book_id?.pincode) {
    return (
      <>
        <Box pt="10px" display="block" width={"100%"}>
          <Typography fontWeight={500}>{"In Store Pickup Address"}</Typography>
        </Box>
        <Box pt="5px" display="block" width={"100%"}>
          <Typography>{record?.book_id.store_id?.store_name}</Typography>
          <Typography> {record?.settingData?.pick_up_address},</Typography>

          <Typography>
            Open: 8am-4pm, Monday-Friday closed weekends and holidays
          </Typography>
          <Typography> {record?.settingData?.phone}</Typography>
          <Typography> {record?.settingData?.email}</Typography>
        </Box>
      </>
    )
  } else {
    return (
      <>
        <Box pt="10px" display="block" width={"100%"}>
          <Typography fontWeight={500}>
            {translate("resources.commands.section.shipping_address")}
          </Typography>
        </Box>
        <Box pt="5px" display="block" width={"100%"}>
          <Typography>
            {record?.user_id?.first_name} {record?.user_id?.last_name}
          </Typography>
          <Typography>{record?.book_id?.mobile_number}</Typography>
          <Typography>
            {record?.book_id?.house_no_building} {record?.book_id?.street}
          </Typography>
          <Typography>
            {record?.book_id?.city} {record?.book_id?.state}
          </Typography>
          <Typography>{record?.book_id?.pincode}</Typography>
        </Box>
      </>
    )
  }
}

const TravelSheet = () => {
  const record = useRecordContext<Order>()
  const contentRef = useRef(null)

  const printDocument = () => {
    html2canvas((contentRef as any).current, {
      scale: 1.25,
      allowTaint: true,
      useCORS: true,
    }).then((canvas: any) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      })
      ;(pdf as any).addImage(imgData, "JPEG", 0, 0)
      pdf.save(`${record?.order_number}_TS.pdf`)
    })
  }

  return (
    <Box display="flex" border={0} flexDirection="column" alignItems="center">
      <Box
        display="flex"
        border={"1px solid lightgray"}
        maxWidth="750px"
        flexDirection="column"
      >
        <Box
          paddingTop={2}
          paddingRight={5}
          display="flex"
          width={"100%"}
          justifyContent={"end"}
          border={0}
        >
          <button
            style={{ background: "transparent", border: "none" }}
            id="printDocument"
            onClick={printDocument}
          >
            <Typography
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              Download PDF{" "}
            </Typography>
          </button>
        </Box>
        <Box
          display="flex"
          padding={5}
          maxWidth="750px"
          flexDirection="column"
          id="divToPrint"
          ref={contentRef}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignContent="end"
            justifyContent="center"
          >
            <Typography fontSize={35}>Bunny Book</Typography>
            <Typography fontSize={25}>{record.book_size}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="start">
            <Box display="flex" flexDirection="column">
              <img src={record?.bookData?.book_cover_url} height={200} />
            </Box>
            <Box display="flex" flexDirection="column">
              <img src={record?.bookData?.pdf_thumnail} height={200} />
            </Box>
          </Box>
          <Box
            display="flex"
            pt="20px"
            flexDirection="row"
            justifyContent="start"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" alignItems={"center"} width={"100%"}>
                  Date : {""}
                  <DateField source="order_date" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" width={"100%"}>
                  Book Type : {record?.book_type}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" width={"100%"}>
                  Book size : {record?.book_size}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" width={"100%"}>
                  <Box display="flex" pr={5}>
                    QTY : {record?.book_qty}
                  </Box>
                  <Box display="flex">
                    Total Pages : {record?.bookData?.total_pages}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            pt="30px"
            pb="10px"
            flexDirection="row"
            justifyContent="start"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" width={"100%"}>
                  {record?.user_id?.first_name} {record?.user_id?.last_name}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" width={"100%"}>
                  Order Number : {record?.order_number}
                </Box>
                <Box display="flex" width={"100%"}>
                  Store Name : {record?.book_id.store_id?.store_name}
                </Box>
                <Box display="flex" flexWrap={"wrap"} width={"100%"}>
                  <CustomerAddress />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrderTravelSheet
