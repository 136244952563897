import BookManagerIcon from "@mui/icons-material/Bookmark"

import BookManagerList from "./BookManagerList"
import BookManagerEdit from "./BookManagerEdit"
import BookManageCreate from "./BookManageCreate"

export default {
  create: BookManageCreate,
  list: BookManagerList,
  edit: BookManagerEdit,
  icon: BookManagerIcon,
}
