import * as React from "react"
import { Link, FieldProps, useRecordContext } from "react-admin"
import { SxProps, Avatar, Typography } from "@mui/material"

import { Customer } from "../types"

const UserLinkField = (props: FieldProps<Customer>) => {
  const record = useRecordContext<Customer>()
  if (!record.userData) {
    return null
  }
  const size = 100
  return (
    <Link to={`/app-users/${record.userData._id}`}>
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      >
        <Avatar
          src={`${record.userData.profile_image}?size=${size}x${size}`}
          style={{ width: 20, height: 20 }}
          sx={{
            mr: 1,
            mt: -0.5,
            mb: -0.5,
          }}
        />
        {record.userData.first_name}
      </Typography>
    </Link>
  )
}

UserLinkField.defaultProps = {
  source: "userData",
}

export default UserLinkField
