import * as React from "react"
import { Table, TableBody, TableCell, TableRow } from "@mui/material"
import { useRecordContext, useTranslate } from "react-admin"

import { Order } from "../types"
import { TableCellRight } from "./TableCellRight"

const Totals = () => {
  const record = useRecordContext<Order>()
  const translate = useTranslate()

  return (
    <Table sx={{ minWidth: "35em" }}>
      <TableBody>
        <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.sum")}
          </TableCell>
          <TableCellRight>
            {(record.book_id.price * record.book_qty).toLocaleString(
              undefined,
              {
                style: "currency",
                currency: "USD",
              }
            )}
          </TableCellRight>
          {/* <TableCellRight>{record.total_amount.toFixed(2)}</TableCellRight> */}
        </TableRow>
        <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.delivery")}
          </TableCell>
          <TableCellRight>
            {parseFloat(record?.delivery_fees).toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>
        {/* <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.taxes")}
            (
            {record?.tax.toLocaleString(undefined, {
              style: "percent",
            })}
            )
          </TableCell>
          <TableCellRight>
            {(
              record?.total_amount -
              record?.delivery_fees -
              record.book_id.price * record.book_qty
            ).toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow> */}
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>
            {translate("resources.commands.fields.basket.total")}
          </TableCell>
          <TableCellRight sx={{ fontWeight: "bold" }}>
            {record.total_amount.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default Totals
