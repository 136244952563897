import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material"
import {
  DateField,
  Edit,
  Form,
  FormDataConsumer,
  Labeled,
  SelectInput,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
  useTranslate,
} from "react-admin"
import { Link as RouterLink } from "react-router-dom"

import { Customer, Order } from "../types"
import Basket from "./Basket"
import Totals from "./Totals"

const OrderEdit = () => (
  <Edit title={"Order Info"} component="div">
    <OrderForm />
  </Edit>
)

const CustomerDetails = () => {
  const record = useRecordContext<Customer>()
  return (
    <div>
      <Typography
        component={RouterLink}
        color="primary"
        to={`/app-users/${record?.user_id?._id}`}
        style={{ textDecoration: "none", display: "block" }}
      >
        {record?.user_id?.first_name} {record?.user_id?.last_name}
      </Typography>
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record?.user_id?.email}`}
        style={{ textDecoration: "none", display: "block" }}
      >
        {record?.user_id?.email}
      </Typography>
      <Typography style={{ textDecoration: "none", display: "block" }}>
        {record?.user_id?.phone}
      </Typography>
    </div>
  )
}

const CustomerAddress = () => {
  const record = useRecordContext<Customer>()
  const translate = useTranslate()

  if (!record?.book_id?.pincode) {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          {"In Store Pickup Address"}
        </Typography>
        <div>
          {/* <Typography>{record?.book_id.store_id?.store_name}</Typography>
          <Typography>{record?.book_id.store_id?.store_address}</Typography> */}
          <Typography>{record?.book_id.store_id?.store_name}</Typography>
          <Typography> {record?.settingData?.pick_up_address},</Typography>

          <Typography>
            Open: 8am-4pm, Monday-Friday closed weekends and holidays
          </Typography>
          <Typography> {record?.settingData?.phone}</Typography>
          <Typography> {record?.settingData?.email}</Typography>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          {translate("resources.commands.section.shipping_address")}
        </Typography>
        <div>
          <Typography>
            {record?.user_id?.first_name} {record?.user_id?.last_name}
          </Typography>
          <Typography>{record?.book_id?.mobile_number}</Typography>
          <Typography>
            {record?.book_id?.house_no_building} {record?.book_id?.street}
          </Typography>
          <Typography>
            {record?.book_id?.city} {record?.book_id?.state}
          </Typography>
          <Typography>{record?.book_id?.pincode}</Typography>
        </div>
      </>
    )
  }
}

const Spacer = () => <Box m={1}>&nbsp;</Box>

const OrderForm = () => {
  const translate = useTranslate()
  const record = useRecordContext<Order>()

  const getStatusOption = () => {
    const statusOptions = [
      {
        id: "1",
        name: "Ready For Pickup",
      },
      {
        id: "2",
        name: "Cancelled",
      },
      {
        id: "4",
        name: "In Progress",
      },
      {
        id: "6",
        name: "Delivered",
      },
    ]

    if (record?.book_id?.pincode) {
      statusOptions.push({
        id: "7",
        name: "Shipped",
      })
    }

    return statusOptions
  }

  return (
    <>
      <div style={{ height: "60px" }}> </div>
      <Form>
        <Box maxWidth="50em">
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.commands.section.order")}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="order_date">
                        <DateField source="order_date" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="order_number">
                        <TextField source="order_number" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <SelectInput
                        emptyText="Pending"
                        emptyValue={"3"}
                        defaultValue={"3"}
                        source="order_status"
                        choices={getStatusOption()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.commands.section.customer")}
                  </Typography>
                  <CustomerDetails />
                  <Spacer />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  {record?.book_id.pincode ? (
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }) =>
                        formData &&
                        (formData.order_status === "7" ||
                          formData.order_status === 7) && (
                          <>
                            <TextInput
                              type="text"
                              source="tracking_number"
                              isRequired
                              fullWidth
                            />
                            <TextInput
                              type="text"
                              source="logistic_name"
                              label="Carrier details"
                              isRequired
                              fullWidth
                            />
                          </>
                        )
                      }
                    </FormDataConsumer>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomerAddress />
                </Grid>
              </Grid>
              <Spacer />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextInput
                    source="note"
                    isRequired
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
              </Grid>
              <Spacer />
              <Typography variant="h6" gutterBottom>
                {translate("resources.commands.section.items")}
              </Typography>
              <div>
                <Basket />
              </div>
              <Spacer />

              <Typography variant="h6" gutterBottom>
                {translate("resources.commands.section.total")}
              </Typography>
              <div>
                <Totals />
              </div>
            </CardContent>

            <Toolbar />
          </Card>
        </Box>
      </Form>
    </>
  )
}

export default OrderEdit
