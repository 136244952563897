import * as React from "react"
import {
  BooleanField,
  Datagrid,
  List,
  Pagination,
  TextField,
} from "react-admin"
import UserLinkField from "./UserLinkField"
import MycustomeDeleteButton from "./MycustomeDeleteButton"

const AppUserList = () => {
  return (
    <>
      <div style={{ height: "30px" }}> </div>

      <List
        // filters={isSmall ? visitorFilters : undefined}
        sort={{ field: "first_name", order: "ASC" }}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 30, 50]} />}
      >
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "table-cell" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <UserLinkField sortable={false} />
          {/* <TextField source="first_name" label="User Name" sortable={false} /> */}
          <TextField source="email" label="Email Address" sortable={false} />
          <TextField source="phone" label="Phone Number" sortable={false} />
          <TextField
            source="device_type"
            label="Device Type"
            sortable={false}
          />
          {/* <TextField source="language" label="Language" sortable={false} /> */}
          {/* <BooleanField source="isCommunication" defaultChecked={false} sortable={false} /> */}
          <BooleanField
            source="isUserVerify"
            label="Is Verify"
            sortable={false}
          />
          <MycustomeDeleteButton />
        </Datagrid>
      </List>
    </>
  )
}

export default AppUserList
