import * as React from "react"
import { useRecordContext } from "react-admin"
import { Customer } from "../types"

const AddressField = () => {
  const record = useRecordContext<Customer>()

  return record ? (
    <span>
      {record?.bookData?.house_no_building} {record?.bookData?.street}{" "}
      {record?.landmark}
      {record?.bookData?.city} {record?.bookData?.zipcode}
    </span>
  ) : null
}

export default AddressField
